import create from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { createTrackedSelector } from 'react-tracked'
import produce from 'immer'
import cacheUtils from 'store'
import { userInfo, setLineCssColor, getTokenCache, setTokenCache } from '@/helper/cache'
import { removeToken } from '@/helper/auth'
import { I18nsEnum } from '@/constants/i18n'
import { UserInfoType } from '@/typings/api/user'
import { setMemberSellProperty, setMemberBuyProperty } from '@/helper/handlecolor'
import { getMemberUserInfo, getMemberBaseSettingsInfo } from '@/apis/user'
import { MemberUserInfoResp, MemberBaseSettingsInfoResp, MemberAuthRefreshTokenResp } from '@/typings/user'
import { toKenTtlDefaultValue } from '@/constants/auth'

type IStore = ReturnType<typeof getStore>

const cacheToken = getTokenCache() as MemberAuthRefreshTokenResp | null
const userTransitionData = 'USER_TRANSITION_DATA'
const personalCenterSettings = 'PERSONAL_CENTER_SETTINGS'
const deviceId = 'DEVICE_ID'

type RestItemType = {
  /** 邮箱 */
  isEmail: boolean
  /** 手机 */
  isMobile: boolean
  /** 谷歌 */
  isGoogle: boolean
}

type UserTransitionDataType = {
  /** 账号 */
  account?: string
  /** 账号类型 手机或邮箱 */
  accountType?: string | number
  /** 第三方账号 */
  thirdPartyAccount?: string
  /** 第三方账号类型 */
  thirdPartyAccountType?: string | number
  /** 注册类型 */
  registerType?: string
  /** 极验码 */
  imageCode?: string
  /** 安全项 */
  item?: number
  /** 邮箱 */
  email?: string
  /** 密码 */
  loginPassword?: string
  /** 国家缩写字母 */
  regCountry?: string
  /** 手机区号 */
  mobileCountryCode?: string
  /** 手机号 */
  mobileNumber?: string
  /** uid */
  uid?: string
  /** 重置安全项选项 */
  resetItem?: RestItemType
  /** 地区值 */
  codeVal?: string
  /** 国家名称 */
  codeKey?: string
  /** 是否可用  */
  enableInd?: number
  /** 国家缩写 */
  remark?: string | null
  /** 目录名 */
  homeColumnName?: string
}
// 判断 refreshToken 过期时间 重置 isLogin 状态
function getIsLoginStatus() {
  if (cacheToken) {
    const isTrue = Date.now() <= cacheToken.refreshTokenExpireTime && !!cacheToken.accessToken
    return isTrue
  }

  return !!cacheToken
}

function getStore(set) {
  return {
    token: cacheToken,
    setToken: (tokenObj: MemberAuthRefreshTokenResp | null) =>
      set((store: IStore) => {
        return produce(store, _store => {
          _store.token = tokenObj
          setTokenCache(tokenObj)
        })
      }),
    isLogin: getIsLoginStatus(),
    setLogin: (values: boolean) => {
      set((store: IStore) => {
        return produce(store, _store => {
          _store.isLogin = values
        })
      })
    },
    userInfo: cacheUtils.get(userInfo) || <UserInfoType>{},
    setUserInfo: (values: Partial<UserInfoType>) =>
      set((store: IStore) => {
        return produce(store, _store => {
          const userInfoFormations = { ..._store.userInfo, ...values }
          _store.userInfo = userInfoFormations
          cacheUtils.set(userInfo, userInfoFormations)
        })
      }),
    removeUserInfo: () =>
      set((store: IStore) => {
        return produce(store, _store => {
          _store.userInfo = <UserInfoType>{}
          cacheUtils.set(userInfo, '')
        })
      }),
    deviceId: cacheUtils.get(deviceId) || '',
    setDeviceId: (values: string) =>
      set((store: IStore) => {
        return produce(store, _store => {
          _store.deviceId = values
          cacheUtils.set(deviceId, values)
        })
      }),
    /** 用户登录、注册过渡数据 */
    userTransitionDatas: cacheUtils.get(userTransitionData) || <UserTransitionDataType>{},
    setUserTransitionDatas: (values: UserTransitionDataType) =>
      set((store: IStore) => {
        return produce(store, _store => {
          const userTranstionData = { ..._store.userTransitionDatas, ...values }
          _store.userTransitionDatas = userTranstionData
          cacheUtils.set(userTransitionData, userTranstionData)
        })
      }),
    removeUserTransitionDatas: () =>
      set((store: IStore) => {
        return produce(store, _store => {
          _store.userTransitionDatas = <UserTransitionDataType>{}
          cacheUtils.set(userTransitionData, '')
        })
      }),
    /** 清除用户登录状态缓存的数据 */
    clearUserCacheData: () => {
      removeToken()
      // removeC2CParamsTipsCache()
      set((store: IStore) => {
        return produce(store, _store => {
          _store.removeUserInfo()
          _store.removeUserTransitionDatas()
          _store.setLogin(false)
        })
      })
    },
    /** 更新用户信息 */
    async updateUserInfoData() {
      const res = await getMemberUserInfo({})

      if (res.isOk) {
        set((store: IStore) => {
          return produce(store, _store => {
            const userInfoFormations = { ..._store.userInfo, ...(res?.data as MemberUserInfoResp) }
            _store.userInfo = userInfoFormations
            cacheUtils.set(userInfo, userInfoFormations)
          })
        })
      }
    },
  }
}
const baseUserStore = create(subscribeWithSelector(getStore))

const useUserStore = createTrackedSelector(baseUserStore)

// 添加监听，A 模块变动去修改 B 模块状态
const unUserSub = baseUserStore.subscribe(
  state => !!state.token,
  val => {
    baseUserStore.getState().setLogin(val)
  }
)

export { useUserStore, baseUserStore, unUserSub }
